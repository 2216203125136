import { RestaurantObject, RestaurantOutageProductsObject } from '@acrelec-cloud/apico-sdk';
import { isEqual } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Footer } from 'src/components/Footer/Footer';
import { Navbar } from 'src/components/Header/Navbar';
import { IssuePage } from 'src/components/IssuePage';
import { GlobalModal } from 'src/components/Modal/CustomModals/GlobalModal';
import { PaymentSuccess } from 'src/components/Payment/Nepting/PaymentSuccess';
import { PopoverComponent } from 'src/components/Popover/PopoverComponent';
import { RestaurantDispatcher } from 'src/components/Restaurant/RestaurantDispatcher';
import { TechnicalIssueController } from 'src/components/TechnicalIssueController';
import { useStore } from 'src/contexts/store.context';
import { useRestaurantStatusChecks } from 'src/hooks/restaurant-status-checks.hook';
import { AccountCreationMailSentPage } from 'src/pages/AccountCreationMailSentPage';
import { CheckoutPage } from 'src/pages/CheckoutPage';
import { ContactPage } from 'src/pages/ContactPage';
import { CurrentOrderPage } from 'src/pages/CurrentOrderPage';
import { DetailedFeedbackPage } from 'src/pages/DetailedFeedbackPage';
import { EditOrderPage } from 'src/pages/EditOrderPage';
import { ForgotPasswordPage } from 'src/pages/ForgotPasswordPage';
import { HomeMenuPage } from 'src/pages/HomeMenuPage';
import { ConsumerGoodsTerms } from 'src/pages/LegalsPage/ConsumerGoodsTerms';
import { PrivacyPolicy } from 'src/pages/LegalsPage/PrivacyPolicy';
import LoginPage from 'src/pages/LoginPage';
import { OrderHistoryPage } from 'src/pages/OrderHistoryPage';
import { OrderPage } from 'src/pages/OrderPage';
import { PaymentCancel } from 'src/pages/Payment/PaymentCancel';
import { PaymentRefused } from 'src/pages/Payment/PaymentRefused';
import { ProductPage } from 'src/pages/ProductPage';
import { ProfileConfirmationPage } from 'src/pages/ProfileConfirmationPage';
import { ProfileCreationPage } from 'src/pages/ProfileCreationPage';
import { ProfilePage } from 'src/pages/ProfilePage';
import { ProfileUpdatePage } from 'src/pages/ProfileUpdatePage';
import { ReportProblemPage } from 'src/pages/ReportProblemPage';
import { ResetPasswordPage } from 'src/pages/ResetPasswordPage';
import { RestaurantAuthenticationPage } from 'src/pages/RestaurantAuthenticationPage';
import { RestaurantsPage } from 'src/pages/RestaurantsPage';
import { ThemeStylesPage } from 'src/pages/ThemeStylesPage';

import { ProtectedRoute } from './ProtectedRoute';

export enum GlobalRouterModal {
  NULL,
  AVAILABLE_RESTAURANT = 'AVAILABLE_RESTAURANT',
  UNAVAILABLE_RESTAURANT = 'UNAVAILABLE_RESTAURANT',
  CAMPAIGN_CHANGE = 'CAMPAIGN_CHANGE',
  UNAVAILABLE_PRODUCT = 'UNAVAILABLE_PRODUCT',
}

export const GlobalRouter = observer(() => {
  const { checkRestaurantStatus, defaultInterval } = useRestaurantStatusChecks();
  const [modalStatus, setModalStatus] = useState<GlobalRouterModal>(GlobalRouterModal.NULL);

  const {
    auth: { isLogged, guest, currentAuthenticated, logout },
    basket: { setCampaignId, isBasketEmpty, products },
    restaurant: {
      currentRestaurant,
      orderingDisabled,
      fetchRestaurants,
      fetchRestaurant,
      setCurrentRestaurant,
    },
    navigation: { fetchNavigation },
    order: { findConflicts, fetchCurrentOrders },
  } = useStore();

  useEffect(() => {
    const timer = setInterval(() => {
      if (isLogged || !!guest) {
        fetchCurrentOrders(20, 0, process.env.REACT_APP_CURRENT_ORDER_FILTERS);
      }
    }, 1000 * 60 * 5); // fetch orders to update current orders count every 5min

    return () => clearInterval(timer);
  }, [isLogged, guest, fetchCurrentOrders]);

  // On init app, run the listener for campaign changes
  useEffect(() => {
    const timer = window.setInterval(() => {
      currentRestaurant &&
        fetchRestaurant(currentRestaurant.id)
          .then(async (restaurantUpdated: RestaurantObject) => {
            const modalChildren = document.getElementById('modal')!.childNodes.length;
            const noModalOpen = modalChildren === 0;
            const changedOrdersEnabled =
              restaurantUpdated.settings.ordersEnabled !== currentRestaurant.settings.ordersEnabled;

            if (noModalOpen) {
              // Manage OrderEnable: Always checking (if none modal is open) and handle modal
              const OrderNotEnabled: GlobalRouterModal = checkRestaurantStatus(restaurantUpdated);
              setModalStatus(GlobalRouterModal[OrderNotEnabled as keyof typeof GlobalRouterModal]);
            }

            const changedCampaignId =
              restaurantUpdated.currentCampaignId !== currentRestaurant.currentCampaignId;

            if (changedCampaignId) {
              // Manage campaignId: Always checking and remove all items that has any change
              fetchNavigation();
              if (!isBasketEmpty) {
                // handle conflicts when price, parts or status of items change
                findConflicts().then((hasConflicts: boolean) => {
                  if (hasConflicts) {
                    setModalStatus(GlobalRouterModal.CAMPAIGN_CHANGE);
                  }
                });
              }
            }

            // Check if a product in basket is unavailable
            const basketContent = toJS(products);
            basketContent.map((product: any) => {
              const productUnavailable =
                currentRestaurant.outageProducts &&
                  currentRestaurant.outageProducts.find(
                    (outage: RestaurantOutageProductsObject) => outage.productCode === product.code,
                  )
                  ? true
                  : false;
              if (productUnavailable) {
                // A product in basket seem unavailable
                setModalStatus(GlobalRouterModal.UNAVAILABLE_PRODUCT);
              }
              return null;
            });

            const restaurantChanged = !isEqual(restaurantUpdated, currentRestaurant);

            return { restaurantUpdated, changedOrdersEnabled, changedCampaignId, restaurantChanged };
          })
          .then(
            ({
              restaurantUpdated,
              restaurantChanged,
            }: {
              restaurantUpdated: RestaurantObject;
              restaurantChanged: boolean;
            }) => {
              // Update store when it changes
              if (restaurantChanged) {
                // fetchRestaurants();
                setCurrentRestaurant(restaurantUpdated);

                if (!isBasketEmpty) {
                  // update the campaignId at basket store
                  setCampaignId(restaurantUpdated.currentCampaignId);
                }

                return;
              }
            },
          );
    }, defaultInterval);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRestaurant]);

  // Check if the api returns an authenticated user
  // If yes, check if the the auth store doesn't have a guest nor a profile, otherwise call logout
  // If yes, check if the the auth store has a guest or a profile, otherwise call logout reset auth store
  useEffect(() => {
    if (isLogged || guest) {
      currentAuthenticated().then((data) => {
        if (!data) {
          logout();
        }
      }).catch((e) => {
        logout();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <TechnicalIssueController />
      <Navbar />
      <Switch>
        {/* PROBLEMS */}
        <Route exact path="/404">
          <IssuePage is404={true} />
        </Route>
        <Route exact path="/technical-issue">
          <IssuePage />
        </Route>

        {/* HOME */}
        <Route exact path="/" component={HomeMenuPage} />

        {/* ORDER */}
        <ProtectedRoute
          exact={true}
          redirectLink="/restaurants"
          canAccess={!!currentRestaurant}
          path="/order">
          <OrderPage />
        </ProtectedRoute>
        <ProtectedRoute
          redirectLink="/restaurants"
          canAccess={!!currentRestaurant}
          path="/order/:id">
          <OrderPage />
        </ProtectedRoute>
        <Route exact path="/cancel-order/:orderId/:guestId?/:accessToken?">
          <EditOrderPage
            title="CancelOrderPage.title"
            isPickUpModification={false}
            isCancellation={true}
          />
        </Route>
        <Route exact path="/modify-pickup/:orderId/:guestId?/:accessToken?">
          <EditOrderPage
            title="ModifyOrderPickupPage.title"
            isPickUpModification={true}
            isCancellation={false}
          />
        </Route>
        <Route exact path="/current-order">
          <CurrentOrderPage />
        </Route>

        {/* ORDER HISTORY */}
        <ProtectedRoute
          exact
          path="/order-history"
          canAccess={isLogged || !!guest}
          redirectLink="/login">
          <OrderHistoryPage />
        </ProtectedRoute>

        {/* RESTAURANTS */}
        <Route exact path="/restaurants" component={RestaurantsPage} />
        <Route exact path="/restaurants/:slugOrId" component={RestaurantDispatcher} />
        <Route
          exact
          path="/restaurants/:slugOrId/authenticate"
          component={RestaurantAuthenticationPage}
        />

        {/* CONTENT */}
        <ProtectedRoute redirectLink="/order" canAccess={!!currentRestaurant} path="/product/:id">
          <ProductPage />
        </ProtectedRoute>

        {/* CHECKOUT & PAYMENT */}
        <ProtectedRoute
          exact
          redirectLink="/order"
          canAccess={!isBasketEmpty && !!currentRestaurant && !orderingDisabled}
          path="/checkout">
          <CheckoutPage />
        </ProtectedRoute>

        <Route exact path="/payment-success">
          <PaymentSuccess />
        </Route>

        <Route exact path="/payment-refused">
          <PaymentRefused />
        </Route>

        <Route exact path="/payment-cancel">
          <PaymentCancel />
        </Route>

        {/* CURRENT ORDER */}
        <Route exact path="/current-order">
          <CurrentOrderPage />
        </Route>
        <Route exact path="/current-order/conflict/:orderConflictId" component={CurrentOrderPage} />

        {/* AUTH */}
        <ProtectedRoute exact path="/profile" canAccess={isLogged} redirectLink="/login">
          <ProfilePage />
        </ProtectedRoute>
        <Route exact path={`/profile/forgot-password/:action`} component={ForgotPasswordPage} />
        <ProtectedRoute path="/profile/create" canAccess={!isLogged} redirectLink="/profile">
          <ProfileCreationPage />
        </ProtectedRoute>
        <ProtectedRoute path="/profile/update" canAccess={isLogged} redirectLink="/">
          <ProfileUpdatePage />
        </ProtectedRoute>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <ProtectedRoute
          path="/profile/confirmation/:token"
          canAccess={!isLogged}
          redirectLink="/login">
          <ProfileConfirmationPage />
        </ProtectedRoute>

        {/* MAILS */}
        <Route exact path="/profile/mail-sent" component={AccountCreationMailSentPage} />
        <Route exact path="/profile/reset-password/:token" component={ResetPasswordPage} />

        {/* LEGALS */}
        {/* <Route exact path="/privacy-policy" component={LegalsPage} /> */}
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-and-conditions" component={ConsumerGoodsTerms} />


        {/* CONTACT */}
        <Route exact path="/contact" component={ContactPage} />

        {/* TODO: Talk about this page */}
        <Route exact path="/theme-styles" component={ThemeStylesPage} />

        {/* SURVEY STUDY */}
        <Route exact path="/report-problem/:orderId" component={ReportProblemPage} />
        <Route exact path="/feedback/:orderId" component={DetailedFeedbackPage} />

        {/* REDIRECT */}
        <Redirect to="/404" />
      </Switch>
      <Footer />
      <PopoverComponent />
      <GlobalModal status={modalStatus} />
    </Router>
  );
});
